import * as React from "react";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import gql from "graphql-tag";
import Json from "../../../common/ui/json";
import {useQuery} from "@apollo/client";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import {usePagination} from "../../../common/hooks/usePagination";
import {useSort} from "../../../common/hooks/useSort";
import {useT} from "../../../common/i18n";
import {useParams} from "react-router";
import { useFilter } from "../../../common/hooks/useFilter";
import { thirtyOneDays } from "../../../common/magicNumbers";
import { useContext, useEffect, useState } from "react";
import {FeatureContext} from "../../../common/context/featureContext";
import {FeatureNotEnabled} from "../../../common/featureNotEnabled";
import { Log } from "../../../common/log";

const QUERY_SENSOR_DATA = gql`
    query device($devId: ID!, $page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType) {
        sensorData(devId: $devId, page: $page, filter: $filter, sort: $sort) {
            id
            receivedAt
            createdAt
            dataRaw
            type
            parseError
            datasourceString
        }
        device(id: $devId) {
            addr
            id
            name
            description
            configRaw
            deviceType {
                id
                name
            }
            app {
                id
                configRaw
            }
            lastReceived
        }
    }
`;

export default function DeviceSensorData() {
    const license = useContext(FeatureContext)
    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <FeatureNotEnabled />
    }
    let initialFilter = [
        { field: "receivedAt", op: "lte", value: new Date(Date.now()) },
        { field: "receivedAt", op: "gte", value: new Date(Date.now() - thirtyOneDays) },
    ]

    const devId = useParams().deviceId
    const t = useT()

    const sensorDataResult = useQuery(QUERY_SENSOR_DATA, {
        fetchPolicy: "cache-and-network",
        variables: {
            devId: devId,
        },
    })
    const sort = useSort(sensorDataResult.refetch)
    const page = usePagination(100, sensorDataResult.refetch)

    const filters = useFilter(true, initialFilter, sensorDataResult.refetch)

    // on startup get the device lastReceived date to define initial filter range
    const [defaultDateRangeInit, setDefaultDateRangeInit] = useState(false)
    useEffect(() => {
        if (sensorDataResult?.data?.device?.lastReceived && !defaultDateRangeInit) {
            let lastReceived = new Date(sensorDataResult?.data?.device?.lastReceived)

            // invalid date is NaN
            let invalid = isNaN(lastReceived.getTime())
            if ( !invalid ) {
                filters.setFilter([
                    { field: "receivedAt", op: "lte", value: lastReceived },
                    { field: "receivedAt", op: "gte", value: new Date(lastReceived - thirtyOneDays) },
                ])
                Log.Info("deviceSensorData",  "setting default filter time range based on last received uplink", lastReceived, "- 31 days")
                setDefaultDateRangeInit(true)
            } else {
                Log.Info("deviceSensorData", "last received uplink time", lastReceived, "setting default filter time range now - 31 days")
            }
        }
    }, [sensorDataResult?.data?.device?.lastReceived])

    let data = sensorDataResult.data
    const defaultTableConfig = {
        cols: [
            {
                heading: t("device.sensor-data.table.received", "Received"),
                width: "10rem",
                cell: {
                    format: "{{date receivedAt 'DD.MM.YYYY HH:mm:ss'}}",
                },
            },
            {
                heading: t("device.sensor-data.table.type", "Type"),
                width: "8rem",
                cell: {
                    format: "{{type}}",
                },
                filter: {
                    type: "string",
                    property: "data_type",
                    hasFilter: true,
                },
            },
            {
                heading: t("device.sensor-data.table.datasource", "Datasource"),
                width: "8rem",
                cell: {
                    format: "{{datasourceString}}",
                },
            },
            {
                heading: t("device.sensor-data.table.parser-result", "Parser Result"),
                cell: {
                    format: "{{parseError}}",
                },
            },
        ],
    }

    //refetch sensor data every 5 seconds if page offset is 0
    // useEffect(() => {
    //     // const interval = setInterval(() => {
    //     //     if (page.pageInfo.offset === 0) {
    //     //         sensorDataResult.refetch()
    //     //     }
    //     // }, 30000)
    //     // return () => {
    //     //     Log.Debug("deviceSensorData", "clearing interval")
    //     //     clearInterval(interval)
    //     // }
    //     Log.Debug("deviceSensorData", "sensorDataResult", sensorDataResult)
    //
    // }, [])

    // update page item count when sensor data changes
    useEffect(() => {
        if (sensorDataResult?.data?.sensorData?.length) {
            page.setPageItemCount(sensorDataResult.data.sensorData.length)
        }
    }, [sensorDataResult?.data?.sensorData?.length])

    const loading = useGraphqlLoadingComponent(sensorDataResult)
    if (loading) {
        return loading
    }

    return (
        <GenericDataTable
            id={"sensor-data-table"}
            tableConfigName={`device-sensor-data-${data.device.deviceType.name}`}
            tableConfigDefault={defaultTableConfig}
            items={sensorDataResult.data.sensorData.map((d) => {
                return {
                    ...d,
                    data: JSON.parse(d.dataRaw),
                }
            })}
            //selectedItem={data.parsedData[0]}
            renderDetails={(item) => {
                return <Json json={item} />
            }}
            gqlResult={sensorDataResult}
            page={page}
            sort={sort}
            filters={filters}
            reloadEnabled={false}
        />
    )
}