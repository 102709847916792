import PropTypes from "prop-types"
import React, { useState } from "react"
import { ButtonGroup, ButtonStateful, Datepicker } from "@salesforce/design-system-react"
import TimePicker from "rc-time-picker"
import "./formSpecificCssFiles/timePicker.css"
import moment from "moment"
import classNames from "classnames"
import slds from "../../slds"
import { Field, getIn, useField, useFormikContext } from "formik"
import requiredIf from "react-required-if"
import _ from "underscore"
import { Log } from "../../log"
import { NotifyUser } from "../../userNotification"
import Button from "../../slds/buttons/button"
import Thumb from "../../slds/fileSelector/thumb"
import { useT } from "../../i18n"

export function validateRequired(required) {
    return (value) => {
        //Log.Debug("Validate required", value);
        // Files are "empty" objects ... So check for the name
        if (value instanceof File) {
            value = value.name
        }

        if (required && _.isEmpty(value)) {
            Log.Debug("Required filed is empty with value", value)
            return `Field is required.`
        }
    }
}

export function errorFromForm(form, field) {
    const t = useT()
    const dynamicT = t
    if (typeof field === "string") {
        field = { name: field }
    }
    //Log.Debug("errorFromForm: ", form, field);
    if (!form || !form.errors) {
        return undefined
    }

    // A readOnly form should not show errors (can't be fixed by user anyway!)
    if (form && form.status && form.status.readOnly) {
        return undefined
    }

    //if GQL Errors extract MessageKeys
    if (field.name === "global") {
        const gqlerrs = getIn(form.errors, "global.graphQLErrors") // form.errors[field.name];
        const msgKeys = []
        for (let i = 0; gqlerrs && gqlerrs?.length && i < gqlerrs?.length; i++) {
            if (gqlerrs[i].extensions?.messageKey) {
                msgKeys.push(gqlerrs[i].extensions?.messageKey)
            }
        }
        if (msgKeys.length > 0) {
            let answer = []
            for (let i = 0; i < msgKeys.length; i++) {
                answer.push(dynamicT("error-keys." + msgKeys[i]))
            }
            return answer.join("\n")
        }
    }

    const err = getIn(form.errors, field.name) // form.errors[field.name];
    if (typeof err === "string") {
        return err
    } else if (err && err.message) {
        return err.message
    } else if (err !== undefined && _.isEmpty(err)) {
        //return "Empty error object, expected undefined";
        return undefined
    }
    if (typeof err === "object") {
        Log.Info("Validation unknown error object: ", err)
        Log.Info("Errors ", form.errors)
        Log.Info("Field ", field)
        return "Unknown error object, return undefined for no error"
    }
    return err
}

export const startEdit = (form) => {
    const { canEdit } = form?.status || {}
    if (!canEdit) {
        return
    }
    form.setStatus({ ...form.status, readOnly: false })
}

// Get props from form status that can be overruled by props set directly to the component
export function getFormStatusProps(form, props) {
    let { readOnly, canEdit } = form?.status || props
    if (props.readOnly !== undefined) {
        readOnly = props.readOnly
    }
    if (props.canEdit !== undefined) {
        canEdit = props.canEdit
    }
    return {
        readOnly,
        canEdit,
    }
}

export const EditIconButton = (props) => {
    const { onClick } = props
    return (
        <Button
            assistiveText={{ icon: "Edit" }}
            iconCategory="utility"
            iconName="edit"
            //iconSize="small"
            iconVariant="bare"
            hint={true}
            onClick={onClick}
            variant="icon"
            noBorder={true}
            {...props}
        />
    )
}
EditIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}

// TODO: pass error string and render the error here inside a React.Fragment - needs some refactoring
export const SldsFormElement = (props) => {
    const { children, error, className, canEdit, readOnly } = props

    //Log.Debug("SldsFormElement.readOnly, canEdit", readOnly, canEdit);

    return (
        <div
            className={classNames(
                "slds-form-element",
                {
                    "slds-has-error": error || false,
                    "slds-form-element_readonly": readOnly,
                    "slds-form-element_edit": canEdit && readOnly,
                },
                className
            )}>
            {children}
        </div>
    )
}
SldsFormElement.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    error: PropTypes.bool,
    readOnly: PropTypes.bool,
    canEdit: PropTypes.bool,
}

export const SldsFormElementControl = (props) => {
    const { className, children } = props
    return <div className={classNames("slds-form-element__control", className)}>{children}</div>
}

SldsFormElementControl.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
}

// Renders a full form element, including title, field error message, etc.
export const SldsFormElementComponent = (props) => {
    //Log.Debug("sldsInput props:", props);
    const { label, renderControl, renderControlReadOnly, required, validate } = props

    let [field] = useField({
        name: props.name,
        validate: validate || validateRequired(required),
    })
    let form = useFormikContext()

    const id = "form-element-" + (props.id || field.name)

    let { readOnly, canEdit } = getFormStatusProps(form, props)

    const error = errorFromForm(form, field)
    if (!field.value) {
        field.value = "" // Ensure form is controlled!
    }

    //Log.Debug("Input.error", error, form, field);
    //Log.Debug("SldsFormElementComponent.readOnly, canEdit", readOnly, canEdit);

    return (
        <SldsFormElement error={!!error} canEdit={canEdit} readOnly={readOnly}>
            {label ? (
                <SldsFormElementLabel htmlFor={id}>
                    {label}
                    {required && !readOnly ? "*" : ""}
                </SldsFormElementLabel>
            ) : null}
            <SldsFormElementControl>
                {readOnly ? (
                    <>
                        <div
                            className={classNames("slds-form-element__static", {
                                "slds-p-right--x-small": canEdit,
                            })}
                            onClick={() => canEdit && startEdit(form)}>
                            {renderControlReadOnly ? renderControlReadOnly() : typeof field.value === "string" ? field.value : JSON.stringify(field.value)}
                        </div>
                        {canEdit ? <EditIconButton onClick={() => startEdit(form)} /> : null}
                    </>
                ) : (
                    renderControl()
                )}
            </SldsFormElementControl>
            {error ? <div className="slds-form-element__help">{error}</div> : null}
        </SldsFormElement>
    )
}
SldsFormElementComponent.propTypes = {
    renderControl: PropTypes.func.isRequired, // The control to render in edit mode
    renderControlReadOnly: PropTypes.func, // The control to render in read only mode, Default: field.value

    id: PropTypes.string,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    onInlineEdit: PropTypes.func,
    validate: PropTypes.func,
}

// Groups multiple form elements with separate heading
// Might contain nested SldsFormElementRow elements
export const SldsFormElementCompound = (props) => {
    return (
        <fieldset className={classNames("slds-form-element slds-form-element_compound", props.className)}>
            {props.label ? <legend className="slds-form-element__legend slds-form-element__label">{props.label}</legend> : null}
            <SldsFormElementControl>{props.children}</SldsFormElementControl>
        </fieldset>
    )
}
SldsFormElementCompound.propTypes = {
    label: PropTypes.string,
}

export const SldsFormElementLabel = (props) => {
    const { required } = props
    return (
        <label className="slds-form-element__label" htmlFor={props.htmlFor}>
            {required ? <span className="slds-required">*</span> : ""}
            {props.children}
        </label>
    )
}
SldsFormElementLabel.propTypes = {
    htmlFor: PropTypes.string,
    children: PropTypes.node.isRequired,
    required: PropTypes.bool,
}

export const SldsFileSelectorField = (props) => {
    const { label, required, accept, withThumb, validate, onFileChange, buttonLabel, thumbWidth, thumbHeight } = props

    let [input, meta, helpers] = useField({
        name: props.name,
        validate: validate || validateRequired(required),
    })
    const t = useT()
    let form = useFormikContext()

    let { readOnly, canEdit } = getFormStatusProps(form, props)
    const error = meta.error
    const id = "upload-" + (props.id || input.name)

    const [dragOver, setDragOver] = useState(false)

    // SldsFormElement readOnly false to fix rendering issues with the width
    return (
        <SldsFormElement error={!!error} canEdit={canEdit} readOnly={false}>
            {label ? (
                <SldsFormElementLabel htmlFor={id}>
                    {label}
                    {required && !readOnly ? "*" : ""}
                </SldsFormElementLabel>
            ) : null}
            <SldsFormElementControl>
                <div
                    className="slds-file-selector slds-file-selector_files"
                    onClick={(e) => {
                        if (readOnly) {
                            e.preventDefault()
                            startEdit(form)
                        }
                    }}>
                    <div
                        className={classNames("slds-file-selector__dropzone", {
                            "slds-has-drag-over": dragOver,
                        })}
                        onDragOver={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            if (readOnly) return
                            setDragOver(true)
                        }}
                        onDragEnter={() => {
                            if (readOnly) return
                            setDragOver(true)
                        }}
                        onDragLeave={() => {
                            setDragOver(false)
                        }}
                        onDrop={(event) => {
                            setDragOver(false)
                            event.preventDefault()
                            event.stopPropagation()
                            if (readOnly) return
                            const files = event.dataTransfer.files
                            Log.Debug(files)

                            if (files.length === 0) {
                                Log.Debug("No file selected!")
                                return
                            }
                            helpers.setValue(files[0])
                            if (onFileChange) {
                                onFileChange(files[0])
                            }
                        }}>
                        <input
                            type="file"
                            disabled={readOnly}
                            className="slds-file-selector__input slds-assistive-text"
                            onChange={(event) => {
                                if (readOnly) return
                                // Prevent clearing the file on "Cancel"
                                // TODO: Do we need a "clear file" button?
                                let file = event.currentTarget.files[0]
                                if (file) {
                                    helpers.setValue(file)
                                    if (onFileChange) {
                                        onFileChange(file)
                                    }
                                }
                            }}
                            accept={accept}
                            id={id}
                            aria-labelledby="file-selector-primary-label file-selector-secondary-label"
                        />
                        <label className="slds-file-selector__body" htmlFor={id} id="file-selector-secondary-label">
                            <span className="slds-file-selector__button slds-button slds-button_neutral">
                                <svg className="slds-button__icon slds-button__icon_left" aria-hidden="true">
                                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#upload" />
                                </svg>
                                {buttonLabel}&nbsp;
                            </span>
                            <span className="slds-file-selector__text slds-medium-show">
                                {input.value && input.value.name ? input.value.name : t("form.file-selector.or-drop-file", "or Drop Files")}
                            </span>
                        </label>
                    </div>
                </div>
            </SldsFormElementControl>
            {error ? <div className="slds-form-element__help">{error}</div> : null}
            {/*TODO: Thumb just supports images yet*/}
            {withThumb ? <Thumb className="slds-m-top--xx-small" width={thumbWidth} height={thumbHeight} file={input.value} /> : null}
        </SldsFormElement>
    )
}

SldsFileSelectorField.defaultProps = {
    buttonLabel: "Upload Files",
}

SldsFileSelectorField.propTypes = {
    // Formik form state
    name: PropTypes.string.isRequired,
    validate: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool, // Accepted content types
    accept: PropTypes.string,
    withThumb: PropTypes.bool,
    thumbWidth: PropTypes.any,
    thumbHeight: PropTypes.any,
    onFileChange: PropTypes.func,
    buttonLabel: PropTypes.string,
}

// In formik forms use SldsInputField
export const SldsInput = (props) => {
    const { label, form, field, autoFocus, placeholder, required, type, sanitize, onInlineEdit } = props

    const id = "input-" + (props.id || field.name)

    let { readOnly, canEdit } = getFormStatusProps(form, props)

    const error = errorFromForm(form, field)
    if (field.value === null) {
        field.value = "" // Ensure form has value
    }

    //Log.Debug("Input.error", error, form, field);

    return (
        <SldsFormElement error={!!error} canEdit={canEdit} readOnly={readOnly} {...props}>
            {label ? (
                <SldsFormElementLabel htmlFor={id}>
                    {label}
                    {required && !readOnly ? "*" : ""}
                </SldsFormElementLabel>
            ) : null}
            <SldsFormElementControl>
                {readOnly ? (
                    <React.Fragment>
                        <div className="slds-form-element__static" onClick={() => startEdit(form)}>
                            {type !== "password" ? field.value : field.value.replaceAll(/./g, "*")}
                        </div>
                        {canEdit ? <EditIconButton onClick={() => startEdit(form)} /> : null}
                    </React.Fragment>
                ) : (
                    <input
                        type={type || "text"}
                        id={id}
                        className="slds-input"
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        readOnly={readOnly}
                        //name, value, onBlur, onChange byFormik from props.field
                        {...field}
                        value={field.value}
                        onChange={(e) => {
                            if (sanitize) {
                                e.target.value = sanitize(e.target.value)
                            }
                            if (field?.onChange) {
                                field.onChange(e)
                            }
                            if (onInlineEdit) {
                                onInlineEdit(e.target.value)
                            }
                        }}
                    />
                )}
            </SldsFormElementControl>
            {error ? <div className="slds-form-element__help">{error}</div> : null}
        </SldsFormElement>
    )
}
SldsInput.propTypes = {
    field: PropTypes.any.isRequired,
    form: PropTypes.any.isRequired,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool,
    canEdit: PropTypes.bool,
    required: PropTypes.bool,
    onInlineEdit: PropTypes.func,
    sanitize: PropTypes.func,
}

export const SldsInputField = ({ required, ...props }) => {
    const validate = props.validate
    return (
        <Field name={props.name} validate={validate || validateRequired(required)}>
            {({ field, form }) => {
                return <SldsInput field={field} form={form} required={required} {...props} />
            }}
        </Field>
    )
}

SldsInputField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool,
    canEdit: PropTypes.bool,
    required: PropTypes.bool,
    onInlineEdit: PropTypes.func,
    sanitize: PropTypes.func,
    className: PropTypes.string,
    validate: PropTypes.func,
    type: PropTypes.oneOf(["text", "number", "password", "email", "hidden"]),
}

export const SldsTextarea = (props) => {
    const { form, field, required, label } = props
    const id = "input-" + (props.id || props.field.name)
    const error = errorFromForm(form, field)
    const { readOnly, canEdit } = getFormStatusProps(form, props)
    if (!field.value) {
        field.value = "" // Ensure form is controlled!
    }
    return (
        <SldsFormElement error={!!error} canEdit={canEdit} readOnly={readOnly} {...props}>
            <SldsFormElementLabel htmlFor={id} required={required}>
                {label}
            </SldsFormElementLabel>
            <div
                className={classNames("slds-form-element__control", {
                    "slds-form-element_readonly": readOnly,
                    "slds-border_bottom": readOnly,
                    "slds-form-element_edit": canEdit,
                })}
                onClick={() => startEdit(form)}>
                {readOnly ? (
                    <React.Fragment>
                        <div className="slds-form-element__static">
                            <p>{field.value}</p>
                        </div>
                        {canEdit ? <EditIconButton onClick={() => startEdit(form)} /> : null}
                    </React.Fragment>
                ) : (
                    <textarea
                        id={id}
                        className="slds-textarea"
                        placeholder={props.placeholder}
                        rows={props.rows}
                        cols={props.cols}
                        autoFocus={props.autoFocus}
                        //name, value, onBlur, onChange byFormik from props.field
                        {...props.field}
                    />
                )}
            </div>
            {error ? <div className="slds-form-element__help">{error}</div> : null}
        </SldsFormElement>
    )
}
SldsTextarea.propTypes = {
    field: PropTypes.any.isRequired,
    form: PropTypes.any.isRequired,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string.isRequired,
    cols: PropTypes.number,
    rows: PropTypes.number,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool,
    onInlineEdit: PropTypes.func,
}

const sanitizeRegex = /[„“]/g

export const SldsTextareaField = ({ required, validate, ...props }) => {
    return (
        <Field
            name={props.name}
            validate={validate || validateRequired(required)}
            render={({ field, form }) => {
                Log.Debug("SldsTextareaField: field.value", field.value)

                //sanitize field.value for „,“
                if (props.sanitize && field.value) {
                    field.value = field.value.replaceAll(sanitizeRegex, '"')
                }
                return <SldsTextarea field={field} form={form} required={required} {...props} />
            }}
        />
    )
}
SldsTextareaField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,

    cols: PropTypes.number,
    rows: PropTypes.number,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool,
    onInlineEdit: PropTypes.func,
}

export const SldsRadioGroup = (props) => {
    const { label, children, readOnly } = props
    const error = false // TODO: We have no access to the form here yet

    return (
        <SldsFormElement error={!!error} readOnly={readOnly} {...props}>
            {label ? <legend className="slds-form-element__legend slds-form-element__label">{label}</legend> : null}
            {error ? <div className="slds-form-element__help">{error}</div> : null}
            <div className="slds-form-element__control">{children}</div>
        </SldsFormElement>
    )
}
SldsRadioGroup.propTypes = {
    children: PropTypes.any.isRequired,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    className: PropTypes.string,
}

// Should be inside a SldsRadioGroup
// For radios in one group the name should be always the same
export const SldsRadio = (props) => {
    const { label, id, field, value } = props
    return (
        <span className="slds-radio">
            <input type="radio" id={id} {...field} value={value} checked={value === field.value} />
            <label className="slds-radio__label" htmlFor={id}>
                <span className="slds-radio_faux" />
                <span className="slds-form-element__label">{label}</span>
            </label>
        </span>
    )
}
SldsRadio.propTypes = {
    value: PropTypes.any.isRequired,
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
}

export const SldsRadioField = (props) => {
    let [field] = useField({
        name: props.name,
    })
    let form = useFormikContext()

    return <SldsRadio field={field} form={form} {...props} />
}

SldsRadioField.propTypes = {
    value: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,

    id: PropTypes.string,
    label: PropTypes.string.isRequired,
}

export const SldsPillCheckbox = ({ className, id, error, label, readOnly, onChange, value }) => {
    const t = useT()
    const [checked, setChecked] = useState(value || false)

    let htmlId = `checkbox-${name}${id ? "-" + id : ""}`

    return (
        <SldsFormElement error={!!error} readOnly={readOnly} className={className}>
            {label !== undefined && (
                <label className="slds-form-element__label" htmlFor={htmlId}>
                    {label}
                </label>
            )}
            <div className={classNames("slds-form-element__control")}>
                <div className="slds-form-element">
                    <label className="slds-checkbox_toggle slds-grid">
                        <span className="slds-form-element__label slds-m-bottom_none" />
                        <input
                            type="checkbox"
                            name="checkbox-toggle-44"
                            value="checkbox-toggle-44"
                            checked={checked}
                            disabled={readOnly}
                            onChange={(e) => {
                                onChange(e)
                                setChecked(e.target.checked)
                            }}
                        />
                        <span id="checkbox-toggle-44" className="slds-checkbox_faux_container">
                            <span className="slds-checkbox_faux"></span>
                            <span className="slds-checkbox_on">{t("checkbox.active", "Active")}</span>
                            <span className="slds-checkbox_off">{t("checkbox.inactive", "Inactive")}</span>
                        </span>
                    </label>
                </div>
            </div>
        </SldsFormElement>
    )
}

export const SldsCheckbox = (props) => {
    const { id, name, label, inlineLabel, field, className, tooltip, form, onChange, isPill } = props
    let htmlId = `checkbox-${name}${id ? "-" + id : ""}`
    const error = errorFromForm(props.form, props.field)
    const t = useT()

    let standalone = false

    if (inlineLabel === undefined && label !== undefined) {
        standalone = true
    }
    let { readOnly } = getFormStatusProps(form, props)

    return (
        <SldsFormElement error={!!error} readOnly={readOnly} className={className}>
            {label !== undefined && (
                <label className="slds-form-element__label" htmlFor={htmlId}>
                    {label}
                </label>
            )}
            <div className={classNames("slds-form-element__control")}>
                <div
                    className={classNames("slds-checkbox", {
                        "slds-checkbox_standalone": standalone,
                    })}>
                    {isPill ? (
                        <div className="slds-form-element">
                            <label className="slds-checkbox_toggle slds-grid">
                                <span className="slds-form-element__label slds-m-bottom_none" />
                                <input
                                    type="checkbox"
                                    name="checkbox-toggle-44"
                                    value="checkbox-toggle-44"
                                    aria-describedby="checkbox-toggle-44"
                                    checked=""
                                    disabled=""
                                />
                                <span id="checkbox-toggle-44" className="slds-checkbox_faux_container">
                                    <span className="slds-checkbox_faux"></span>
                                    <span className="slds-checkbox_on">{t("checkbox.active", "Active")}</span>
                                    <span className="slds-checkbox_off">{t("checkbox.inactive", "Inactive")}</span>
                                </span>
                            </label>
                        </div>
                    ) : (
                        <>
                            <input
                                type="checkbox"
                                name="options"
                                id={htmlId}
                                {...field}
                                checked={!!field.value}
                                disabled={readOnly}
                                readOnly={readOnly}
                                onChange={(e) => {
                                    field.onChange(e)
                                    onChange(e)
                                }}
                            />
                            <label className="slds-checkbox__label" htmlFor={htmlId}>
                                <span className="slds-checkbox_faux"></span>
                                {inlineLabel && <span className="slds-form-element__label">{inlineLabel}</span>}
                            </label>
                        </>
                    )}
                    {tooltip ? ( // TODO: Tooltip seems not to work with "label" instead of "inlineLabel"
                        <slds.Tooltip align="top left" content={tooltip}>
                            <a href="#" onClick={() => false}>
                                <slds.Icon category="utility" name="info" size="x-small" />
                            </a>
                        </slds.Tooltip>
                    ) : null}
                </div>
            </div>
        </SldsFormElement>
    )
}
SldsCheckbox.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object,

    id: PropTypes.string,
    label: PropTypes.string,
    inlineLabel: PropTypes.string,
    className: PropTypes.string,
    tooltip: PropTypes.string,
}

export const SldsCheckboxField = (props) => {
    return (
        <Field
            name={props.name}
            render={({ field, form }) => {
                return <SldsCheckbox field={field} form={form} {...props} />
            }}
        />
    )
}
SldsCheckboxField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string, // inline label
    inlineLabel: PropTypes.string,
    className: PropTypes.string,
    tooltip: PropTypes.string, //standalone: PropTypes.bool,
}

export const SldsSelectField = (props) => {
    let { validate, required, labelExtractor, options, className } = props

    let [field] = useField({
        name: props.name,
        validate: validate || validateRequired(required),
    })
    let form = useFormikContext()

    let displayedOptions = []
    // Use children to extract options for their display
    if (props.children) {
        React.Children.forEach(props.children, (child) => {
            if (child.type === Option) {
                displayedOptions.push({ value: child.props.value, label: child.props.label })
            }
        })
    }

    if (!labelExtractor) {
        if (displayedOptions) {
            labelExtractor = (v) => {
                let res = displayedOptions.find((o) => o.value === field.value)?.label || v
                Log.Debug("res", res)
                return res
            }
        } else {
            labelExtractor = (v) => v
        }
    }

    const id = "select-" + (props.id || field.name)
    const error = errorFromForm(form, field)
    if (!field.value) {
        field.value = "" // Ensure form is controlled!
    }
    let { readOnly, canEdit } = getFormStatusProps(form, props)

    return (
        <SldsFormElement error={!!error} readOnly={readOnly} canEdit={canEdit} className={className}>
            <SldsFormElementLabel htmlFor={id}>{props.label}</SldsFormElementLabel>
            <div className="slds-form-element__control">
                {readOnly ? (
                    <>
                        <div className="slds-form-element__static" onClick={() => startEdit(form)}>
                            {labelExtractor(field.value)}
                        </div>
                        {canEdit ? <EditIconButton onClick={() => startEdit(form)} /> : null}
                    </>
                ) : (
                    <div className="slds-select_container">
                        <select className="slds-select" id={id} {...field}>
                            {props.children ? props.children : null}
                            {options.map((o) => {
                                if (o.value === undefined) return null
                                return (
                                    <option key={o.value} value={o.value} label={o.label}>
                                        {o.label}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                )}
            </div>
            {error ? <div className="slds-form-element__help">{error}</div> : null}
        </SldsFormElement>
    )
}
SldsSelectField.defaultProps = {
    options: [],
}

SldsSelectField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string, // Resolve the display label for a value: (value) => "label"
    labelExtractor: PropTypes.func, // Either options or children is required
    // options object can render ReadOnly with label
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    children: PropTypes.any,
    required: PropTypes.bool,
}

export const Option = (props) => {
    const { value, label, children } = props
    return (
        <option value={value} label={label}>
            {label || children}
        </option>
    )
}

Option.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.string,
}

// Used inside SldsFormElementCompound
export const SldsFormElementRow = (props) => {
    return (
        <div className={classNames("slds-form-element__group", props.className)}>
            <div className="slds-form-element__row">{props.children}</div>
        </div>
    )
}

export const ReactTimePicker = (props) => {
    const { form, field, readOnly } = props
    let { onChange } = props
    if (!onChange) {
        onChange = (value) => {
            form.setFieldValue(field.name, value)
        }
    }

    return (
        <SldsFormElementCompound>
            <SldsFormElementRow>
                <TimePicker defaultValue={field.value} showSecond={false} allowEmpty={false} inputReadOnly={readOnly} onChange={onChange} />
            </SldsFormElementRow>
        </SldsFormElementCompound>
    )
}

export const DateTimePicker = (props) => {
    const { form, field, label, submitFormOnChange } = props

    const handleChange = (date, changeMode) => {

        let newDate = new Date() // teh new date to be set
        let currentDate = form.values[field.name] || new Date() // the current date specified in the form
        let interactionDate = date || new Date() // the date from the datepicker

        if (changeMode === "date") {

            newDate.setDate(interactionDate.getDate())
            newDate.setMonth(interactionDate.getMonth())
            newDate.setFullYear(interactionDate.getFullYear())
            newDate.setHours(currentDate.getHours())
            newDate.setMinutes(currentDate.getMinutes())
            newDate.setSeconds(0)
        }
        if (changeMode === "time") {

            newDate.setDate(currentDate.getDate())
            newDate.setMonth(currentDate.getMonth())
            newDate.setFullYear(currentDate.getFullYear())
            newDate.setHours(interactionDate.getHours())
            newDate.setMinutes(interactionDate.getMinutes())
            newDate.setSeconds(0)
        }
        form.setFieldValue(field.name, newDate)

        if (submitFormOnChange === true) {
            form.handleSubmit()
        }
    }


    // TODO: display form.errors of this field like in https://jaredpalmer.com/formik/docs/api/field
    return (
        <SldsFormElementCompound>
            <SldsFormElementRow>
                <Datepicker
                    labels={{ label: label }}
                    formatter={(date) => moment(date).format("DD.MM.YYYY")}
                    parser={(inputStr) => moment(inputStr, "DD.MM.YYYY").toDate()}
                    value={form.values[field.name]}
                  onChange={(_, data) => handleChange(data.date, "date")}
                />
                <div className="slds-align-bottom">
                    <TimePicker
                        // defaultValue={moment(form.values[field.name])}
                        value={moment(form.values[field.name])}
                        showSecond={false}
                        allowEmpty={false}
                        onChange={(date) => handleChange(date.toDate(), "time")}
                    />
                </div>
            </SldsFormElementRow>
        </SldsFormElementCompound>
    )
}

DateTimePicker.propTypes = {
    field: PropTypes.any.isRequired,
    form: PropTypes.any.isRequired,
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
}

function clearEmpties(o) {
    for (const k in o) {
        if (!o[k] || typeof o[k] !== "object") {
            continue // If null or not an object, skip to the next iteration
        }

        // The property is an object
        o[k] = clearEmpties(o[k])
        if (Object.keys(o[k]).length === 0) {
            delete o[k] // The object had no properties, so delete that property
        }
    }
    return o
}

// Displays form actions and global errors
export const FormActions = (props) => {
    const { buttons, children, hidden } = props

    return (
        <Field
            render={(props) => {
                const { form } = props
                const { readOnly, canEdit } = form.status || {}

                const globalError = errorFromForm(form, "global")

                if (hidden) {
                    return null
                }
                if (!form.isValid && !_.isEmpty(form.errors)) {
                    Log.Debug("form.errors", form.errors, form)
                    // Fix a bug where isValid is false because of empty nested object in form.errors
                    let errors = clearEmpties(form.errors)
                    // Only "re-set" if empty to avoid endless loop
                    if (_.isEmpty(errors)) {
                        form.setErrors(errors)
                    }
                }

                if (readOnly && canEdit) {
                    return (
                        <div className="slds-m-top--small">
                            <EditButtonField>Edit</EditButtonField>
                        </div>
                    )
                }

                return (
                    <div className="slds-form-element slds-has-error slds-m-top--small" hidden={readOnly}>
                        <div className="slds-form-element__control">
                            {buttons ? buttons : null}
                            {children}
                        </div>

                        {!form.isValid && form.submitCount > 0 && !form.errors.global && !_.isEmpty(form.errors) ? (
                            <div className="slds-form-element__help">The form is not valid.</div>
                        ) : null}
                        {globalError ? <div className="slds-form-element__help"> {globalError} </div> : null}
                        <div hidden={true}>
                            {" "}
                            id: {form?.values?.id} type: {form?.values?.type}
                        </div>
                    </div>
                )
            }}
        />
    )
}

FormActions.propTypes = {
    children: PropTypes.any.isRequired,
    hidden: PropTypes.bool,
}

export const IconButtonStateful = ({ children, ...props }) => {
    return (
        <ButtonStateful
            iconCategory={"utility"}
            iconName={"question"}
            iconPosition={"left"}
            variant={children ? "neutral" : "icon"}
            iconSize={children ? undefined : "small"}
            {...props}>
            {children}
        </ButtonStateful>
    )
}

IconButtonStateful.propTypes = {
    iconName: PropTypes.string.isRequired,
    iconCategory: PropTypes.string,
    iconPosition: PropTypes.string,
    iconSize: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(["base", "link", "neutral", "brand", "outline-brand", "destructive", "success", "text-destructive", "icon"]),
    stateOne: PropTypes.object,
    stateTwo: PropTypes.object,
    stateThree: PropTypes.object,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
}

export const SubmitButtonField = (props) => {
    let { label, children, formId, onClick, iconName } = props
    const t = useT()
    let content = label || children
    if (!content) {
        content = t("common.button.save", "Save")
    }
    return (
        <Field
            render={({ form }) => {
                return (
                    <Button
                        type="submit"
                        disabled={form.isSubmitting || props.disabled}
                        iconCategory={"utility"}
                        iconName={iconName}
                        iconPosition={"left"}
                        form={formId}
                        onClick={(e) => {
                            e.persist()
                            if (onClick) {
                                onClick(e)
                            } else {
                                e.preventDefault()
                                // e.stopPropagation();
                                Log.Debug("SubmitButtonField.onClick", form)
                                // form.submitForm() returns a promise that resolves after validation
                                // form.handleSubmit(e) does not work better :(
                                form.submitForm()
                                    .then((e) => {
                                        Log.Debug("SubmitButtonField.submitForm().then(...)", e, form)
                                        if (form.status && form.status.readOnly !== undefined && _.isEmpty(form.errors)) {
                                            form.setStatus({
                                                ...form.status,
                                                readOnly: true,
                                            })
                                        }
                                    })
                                    .catch((e) => {
                                        NotifyUser.Error("Failed to submit Form", e)
                                    })
                                    .finally(() => form.setSubmitting(false))
                            }
                        }}
                        {...props}>
                        {content}
                    </Button>
                )
            }}
        />
    )
}

SubmitButtonField.defaultProps = {
    iconName: "save",
}

SubmitButtonField.propTypes = {
    children: PropTypes.any.isRequired,
    formId: PropTypes.string,
    onClick: PropTypes.func,
    iconName: PropTypes.string,
    ...Button.propTypes,
}

export const SldsButton = (props) => {
    return <Button {...props}>{props.children}</Button>
}

SldsButton.propTypes = {
    /**
     * **Assistive text for accessibility.**
     * This object is merged with the default props object on every render.
     * * `icon`: Text that is visually hidden but read aloud by screenreaders to tell the user what the icon means. If the button has an icon and a visible label, you can omit the <code>assistiveText.icon</code> prop and use the <code>label</code> prop.
     */
    assistiveText: PropTypes.shape({
        icon: PropTypes.string,
    }),

    /**
     * Callback that passes in the DOM reference of the `<button>` DOM node within this component. Primary use is to allow `focus` to be called. You should still test if the node exists, since rendering is asynchronous. `buttonRef={(component) => { if(component) Log.Debug(component); }}`
     */
    buttonRef: PropTypes.func,

    /**
     * CSS classes to be added to button.
     */
    className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

    /**
     * Disables the button and adds disabled styling.
     */
    disabled: PropTypes.bool,

    /**
     * Associates an icon button with another element on the page by changes the color of the SVG. Please reference <a href="http://www.lightningdesignsystem.com/components/buttons/#hint">Lightning Design System Buttons > Hint</a>.
     */
    hint: PropTypes.bool,

    /**
     * Name of the icon category. Visit <a href="http://www.lightningdesignsystem.com/resources/icons">Lightning Design System Icons</a> to reference icon categories.
     */
    iconCategory: requiredIf(PropTypes.oneOf(["action", "custom", "doctype", "standard", "utility"]), function (props) {
        return !!props.iconName
    }),

    /**
     * CSS classes to be added to icon.
     */
    iconClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

    /**
     * Name of the icon. Visit <a href="http://www.lightningdesignsystem.com/resources/icons">Lightning Design System Icons</a> to reference icon names.
     */
    iconName: PropTypes.string,

    /**
     * Url to the icon. This will override any global icon settings.
     */
    iconPath: PropTypes.string,

    /**
     * If omitted, icon position is centered.
     */
    iconPosition: PropTypes.oneOf(["left", "right"]),

    /**
     * Determines the size of the icon.
     */
    iconSize: PropTypes.oneOf(["x-small", "small", "medium", "large"]),

    /**
     * For icon variants, please reference <a href="http://www.lightningdesignsystem.com/components/buttons/#icon">Lightning Design System Icons</a>.
     */
    iconVariant: PropTypes.oneOf(["bare", "container", "border", "border-filled", "brand", "more", "global-header"]),

    /**
     * Id string applied to button node.
     */
    id: PropTypes.string,

    /**
     * If true, button/icon is white. Meant for buttons or utility icons on dark backgrounds.
     */
    inverse: PropTypes.bool,

    /**
     * Visible label on the button. If the button is an icon button with no label, you must use the <code>assistiveText.icon</code> prop.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Triggered when focus is removed.
     */
    onBlur: PropTypes.func,

    /**
     * Triggered when the button is clicked.
     */
    onClick: PropTypes.func,

    /**
     * Triggered when component is focused.
     */
    onFocus: PropTypes.func,

    /**
     * Triggered when a key is pressed down
     */
    onKeyDown: PropTypes.func,

    /**
     * Triggered when a key is pressed and released
     */
    onKeyPress: PropTypes.func,

    /**
     * Triggered when a key is released
     */
    onKeyUp: PropTypes.func,

    /**
     * Triggered when a mouse button is pressed down
     */
    onMouseDown: PropTypes.func,

    /**
     * Triggered when a mouse arrow hovers
     */
    onMouseEnter: PropTypes.func,

    /**
     * Triggered when a mouse arrow no longer hovers
     */
    onMouseLeave: PropTypes.func,

    /**
     * Triggered when a mouse button is released
     */
    onMouseUp: PropTypes.func,

    /**
     * If true, button scales to 100% width on small form factors.
     */
    responsive: PropTypes.bool,

    /**
     * Write <code>"-1"</code> if you don't want the user to tab to the button.
     */
    tabIndex: PropTypes.string,

    /**
     * Button type
     */
    type: PropTypes.oneOf(["reset", "submit", "button"]),

    /**
     * HTML title attribute
     */
    title: PropTypes.string,

    /**
     * [Deprecated] Tooltip on button. Button should be a child of `Tooltip` instead.
     */
    tooltip: PropTypes.node,

    /**
     * Different types of buttons
     */
    variant: PropTypes.oneOf(["base", "link", "neutral", "brand", "outline-brand", "destructive", "success", "text-destructive", "icon"]),

    /**
     * Custom styles to be passed to the component
     */
    style: PropTypes.object,
}

export const SldsButtonGroup = (props) => {
    return <ButtonGroup {...props}>{props.children}</ButtonGroup>
}

SldsButtonGroup.propTypes = {
    /**
     * Children are expected to be components. If last button triggers a dropdown menu, use Dropdown instead of Button. _Tested with snapshot testing._
     */
    children: PropTypes.node.isRequired,

    /**
     * CSS classes added to `slds-button-group` or `slds-checkbox_button-group` tag
     */
    className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

    /**
     * **Text labels for internationalization**
     * This object is merged with the default props object on every render.
     * * `error`: Message to display when any of Checkboxes are in an error state. _Tested with snapshot testing._
     * * `label`: This label appears above the button group. _Tested with snapshot testing._
     */
    labels: PropTypes.shape({
        error: PropTypes.string,
        label: PropTypes.string,
    }),

    /**
     * Use checkbox variant for "Checkbox Button Group" styling and add Checkbox components as children _Tested with snapshot testing._
     */
    variant: PropTypes.oneOf(["checkbox", "list"]),
}

// Button to cancel editing of a form
export const CancelButtonField = ({ label, onClick, children, ...props }) => {
    const t = useT()
    let content = label || children
    if (!content) {
        content = t("common.button.cancel", "Cancel")
    }
    let { variant } = props
    return (
        <Field
            render={({ form }) => {
                return (
                    <Button
                        variant={variant}
                        disabled={form.isSubmitting}
                        onClick={(e) => {
                            form.resetForm()
                            if (form.status && form.status.readOnly !== undefined) {
                                form.setStatus({
                                    ...form.status,
                                    readOnly: true,
                                })
                            }
                            if (onClick) {
                                onClick(e)
                            }
                        }}
                        iconCategory={"utility"}
                        iconName={"close"}
                        iconPosition={"left"}
                        {...props}>
                        {content}
                    </Button>
                )
            }}
        />
    )
}

CancelButtonField.defaultProps = {
    variant: "destructive",
}

CancelButtonField.propTypes = {
    label: PropTypes.string, //Deprecated, use children
    onClick: PropTypes.func,
    children: PropTypes.string,
    variant: PropTypes.oneOf(["base", "neutral", "brand", "outline-brand", "destructive", "success", "text-destructive"]),
}

export const EditButtonField = ({ label, onClick, children, ...props }) => {
    return (
        <Field
            render={({ form }) => {
                const { readOnly } = form.status || {}
                if (!readOnly) return null
                return (
                    <Button
                        disabled={form.isSubmitting}
                        onClick={(e) => {
                            if (form.status && form.status.readOnly !== undefined) {
                                form.setStatus({
                                    ...form.status,
                                    readOnly: false,
                                })
                            }
                            if (onClick) {
                                onClick(e)
                            }
                        }}
                        iconCategory={"utility"}
                        iconName={"edit"}
                        iconPosition={"left"}
                        {...props}>
                        {label || children}
                    </Button>
                )
            }}
        />
    )
}

CancelButtonField.propTypes = {
    label: PropTypes.string, //Deprecated, use children
    onClick: PropTypes.func,
    children: PropTypes.string,
}
