import * as React from "react";
import Page from "../../../common/ui/page";
import Button from "../../../common/slds/buttons/button";
import {Tabs, TabsPanel} from "@salesforce/design-system-react";
import {Link} from "react-router-dom";


import {useT} from "../../../common/i18n";
import {DeviceBulkDownlinkTab} from "./bulkOperations/deviceBulkoperationDownlink";
import {DeviceDataResendTab} from "./bulkOperations/deviceBulkoperationResendData";
import {ChangeDeviceSettingsTab} from "./bulkOperations/changeDeviceSettingsTab";


export const DeviceBulkOperationPage = (props) => {
    const t = useT();
    let {multiSelection, setShowBulkOperationPage} = props;

    return <Page
        title={t("device.bulkoperation.titel","Bulk Operations")}
        actions={
                <Button iconName={"close"} onClick={() => setShowBulkOperationPage(false)}>{t("common.button.cancel", "Cancel")}</Button>
        }
        trail={[<Link onClick={() => {setShowBulkOperationPage(false)}} to="./" key={1}>{t("device.bulkoperation.titel","Bulk Operations")}</Link>]}>

        <Tabs id="tabs-example-default" >
            <TabsPanel label={t("devices.bulkoperation.change-devicesettings","Device Settings")}><ChangeDeviceSettingsTab multiSelection={multiSelection}/></TabsPanel>
            <TabsPanel label={t("devices.bulkoperation.downlink-tab","Downlinks")}><DeviceBulkDownlinkTab multiSelection={multiSelection}/></TabsPanel>
            <TabsPanel label={t("devices.bulkoperation.resend-tab","Resend Data")}><DeviceDataResendTab multiSelection={multiSelection}/></TabsPanel>
        </Tabs>

    </Page>;

}



